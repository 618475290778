'use client'
import { useEffect, useState } from 'react'
import { useUser } from '@/src/store/user-store'
import { useRouter } from 'next/navigation'
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react'
import { Progress } from '@chakra-ui/react'

const RedirectionLoader: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [progress, setProgress] = useState(2)
  const [showContest, setShowContest] = useState(false)
  const router = useRouter()
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => prev + 0.5)
    }, 10)
    setTimeout(() => {
      clearInterval(interval)
      setShowContest(true)
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [])
  const { user, _hasHydrated } = useUser()
  useEffect(() => {
    if (window?.location?.pathname === '/') {
      if (!_hasHydrated) {
        onOpen()
      } else if (_hasHydrated && user) {
        setTimeout(() => {
          router.push('/home')
        }, 2200)
      } else if (_hasHydrated && !user) {
        onClose()
      }
    }
  }, [_hasHydrated])

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        closeOnOverlayClick={false}
        size={'full'}
      >
        <ModalOverlay bg="rgba(0, 0, 0, 0.6)" backdropFilter="blur(10px)" />
        <ModalContent
          bg="transparent"
          textAlign="center"
          p={6}
          maxWidth={'700px'}
          margin={'0px auto'}
          boxShadow={'none'}
        >
          <ModalBody marginTop={'20vh'}>
            <VStack spacing={4}>
              <Box>
                <img
                  src="/lykstage-logo.svg"
                  alt="LYKStage Logo"
                  style={{ width: '290px' }}
                />
                <Text
                  fontSize="16px"
                  fontWeight="bold"
                  color="white"
                  marginTop={'10px'}
                >
                  Watch. Create. Earn.Repeat.
                </Text>
              </Box>
              <Text
                fontSize="clamp(30px, 5vw, 40px)"
                fontWeight="bold"
                color="white"
                marginTop={'50px'}
              >
                Hang tight...
              </Text>
              <Text fontSize="clamp(14px, 4vw, 20px)" color="white">
                Tip: The more you watch content (and ads!) the more you earn!
              </Text>
              <Progress
                colorScheme="red"
                size="sm"
                rounded="md"
                value={progress}
                width="100%"
              />
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default RedirectionLoader
