'use client'
import { useAccessTokenQuery } from '@/src/queries/auth/accesstoken-query'
import { useSessionTracking } from '@/src/queries/guest/session-tracking'
import { useUIDQuery } from '@/src/queries/guest/uid-query'
import { useUserQuery } from '@/src/queries/user/user-details'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { MatomoProvider } from '@datapunt/matomo-tracker-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import instance from '@/lib/matomo'
import { ReactNode, useState } from 'react'
import RedirectionLoader from './redirectionLoader'

const breakpoints = {
  base: '0em',
  xs: '280px',
  sm: '540px',
  md: '768px',
  lg: '990px',
  xl: '1124px',
  '2xl': '1280px',
  '3xl': '1440px',
  '4xl': '1650px',
}

const chakraTheme = extendTheme({
  colors: {
    red: {
      500: '#ED837F',
    },
    'lyk-green': '#24BFA3',
    'lyk-red': '#ED837F',
    'lyk-gray': '#ececec',
    'lyk-black': '#3e3e3e',
    'lyk-textborder': '#d9d9d9',
  },
  Radio: {
    baseStyle: {
      control: {
        _checked: {
          bg: 'lyk-green',
          borderColor: 'lyk-gray',
        },
        _focus: {
          boxShadow: 'none',
        },
      },
    },
  },
  fonts: {
    heading: 'var(--font-sans), system-ui',
    body: 'var(--font-sans), system-ui',
  },
  breakpoints,
})
export function Providers({ children }: { children: React.ReactNode }) {
  const [queryClient] = useState(() => new QueryClient())
  return (
    //@ts-ignore
    <MatomoProvider value={instance}>
      <QueryClientProvider client={queryClient}>
        <AuthWrapper>
          <ChakraProvider theme={chakraTheme}>
            <RedirectionLoader />
            {children}
          </ChakraProvider>
        </AuthWrapper>
      </QueryClientProvider>
    </MatomoProvider>
  )
}

const AuthWrapper = ({ children }: { children: ReactNode }) => {
  useAccessTokenQuery()
  const uidQuery = useUIDQuery()
  useUserQuery()
  useSessionTracking({
    isUIDSet: uidQuery.isFetched,
  })
  return children
}
